import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import './common/css/font-awesome.min.css';

import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";
import CompanyView from "./components/Company/View";

import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";


import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";


import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit"

import DashboardList from "./components/Dashboard/List";

import ColorList from "./components/Color/List";
import ColorEdit from "./components/Color/Edit";
import ColorAdd from "./components/Color/Add";
import ColorView from "./components/Color/View";


import PackageList from "./components/Packages/List";
import PackageAdd from "./components/Packages/Add";
import PackageEdit from "./components/Packages/Edit";


import FormList from "./components/FormBuilder/List";
import FormAdd from "./components/FormBuilder/Add";
import FormEdit from "./components/FormBuilder/Edit";

import PaymentHistoryList from "./components/PaymentHistory/List";
import PaymentHistoryView from "./components/PaymentHistory/View";


import SubscriptionList from './components/Subscription/List';
import SubscriptionAdd from './components/Subscription/Add';
import SubscriptionEdit from './components/Subscription/Edit';

import Cmscontent from './components/Account/Cmscontent';
import Gallery from './components/Account/Gallery';

import Couponlist from "./components/Coupons/List";
import CouponAdd from "./components/Coupons/Add";
import CouponEdit from "./components/Coupons/Edit";



import FormEntriesList from "./components/Formentries/List";
import FormEntriesView from "./components/Formentries/View";



import CategoryList from "./components/Category/List";
import CategoryAdd from "./components/Category/Add";
import CategoryEdit from "./components/Category/Edit";
import CategoryView from "./components/Category/View";

import ProductList from "./components/Product/List";
import ProductView from "./components/Product/View";
import ProductAdd from "./components/Product/Add";
import ProductEdit from "./components/Product/Edit";


import ProductList_outofstock from "./components/Product/List";

import ProductView_outofstock from "./components/Product/View";
import ProductEdit_outofstock from "./components/Product/OutofstockEdit";



import PaymentHistorycompletedList from "./components/PaymentHistory/List";
import PaymentHistorycompletedView from "./components/PaymentHistory/View";

import './common/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" component={Forgetpassword} />
      <Route path="/reset_password/:key" component={Resetpassword} />
      <Route path="/company" element={<Company />} />
      <Route path="/company-add" element={< CompanyAdd />} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      <Route path="/company-view/:ID" element={<CompanyView/>} />
      <Route path="/user" element={<UserList/>} />
      <Route path="/user-add" element={< UserAdd/>} />
      <Route path="/user-edit/:ID" element={<UserEdit/>} />
      <Route path="/user-view/:ID" element={<UserView/>} />
      
  

      <Route path="/dashboard" element={<DashboardList/>} />

  

        <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
        <Route path="/dashboardmanagement/edit/:ID" element={<DashboardEdit/>} />       
        <Route path="/dashboardmanagement-add" element={<DashboardAdd/>} />

        
        <Route path="/font-edit/:ID" element={<FontEdit />} />
        <Route path="/fonts" element={<Fontlist />} />
        <Route path="/font-add" element={<FontAdd />} />

        <Route path="/templates" element={<TemplateList />} />
        <Route path="/template/add" element={<TemplateAdd/>} />
        <Route path="/templates-edit/:ID" element={<TemplateEdit />} />

        <Route path="/color" element={<ColorList/>} />
        <Route path="/color-edit/:ID" element={<ColorEdit/>} />
        <Route path="/color-add" element={<ColorAdd/>} />
        <Route path="/color-view/:ID" element={<ColorView/>} />

        <Route path="/color-add" element={<PackageEdit/>} />
        <Route path="/color-view/:ID" element={<ColorView/>} />


        <Route path="/packages-edit/:ID" element={<PackageEdit/>} />
        <Route path="/packages" element={<PackageList/>} />
        <Route path="/package-add" element={<PackageAdd/>} />


        <Route path="/formbuilder/edit/:ID" element={<FormEdit/>} />
        <Route path="/formbuilder" element={<FormList/>} />
        <Route path="/form-add" element={<FormAdd/>} />

        <Route path="/coupons" element={<Couponlist/>} />
	    	<Route path="/coupon-add" element={<CouponAdd/>} />
        <Route path="/coupon-edit/:ID" element={<CouponEdit/>} />

        <Route path="/subscriptions" element={<SubscriptionList/>} />
        <Route path="/subscription/add" element={<SubscriptionAdd/>} />
        <Route path="/subscription-edit/:ID" element={<SubscriptionEdit/>} />

        <Route path="/payment-history" element={<PaymentHistoryList/>} />
         <Route path="/payment-view/:ID" element={<PaymentHistoryView/>} />

         <Route path='/plugincontent' element={<Cmscontent/>} />
         <Route path="/product-gallery" element={<Gallery/>} />


         <Route path="/formentries" element={<FormEntriesList/>} />
        <Route path="/formentries-view/:ID" element={<FormEntriesView/>} />


        <Route path="/category"  element={<CategoryList/>} />
        <Route path="/category-add" element={<CategoryAdd/>} />
        <Route path="/category-edit/:ID" element={<CategoryEdit/>} />
        <Route path="/category-view/:ID" element={<CategoryView/>} />


        <Route path="/product" element={<ProductList />} />
       
        <Route path="/product-view/:ID" element={<ProductView/>} />
        <Route path="/product-add" element={<ProductAdd/>} />
        <Route path="/product-edit/:ID" element={<ProductEdit/>} />
      
        <Route path="/product-outstock" element={<ProductList_outofstock />} />
        <Route path="/product-view-outstock/:ID" element={<ProductView_outofstock/>} />
        <Route path="/product-edit-outstock/:ID" element={<ProductEdit_outofstock/>} />
      


        <Route path="/payment-completed-history" element={<PaymentHistorycompletedList/>} />
        <Route path="/payment-completed-view/:ID" element={<PaymentHistorycompletedView/>} />

    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
